import React from 'react'
import withLandingNamespaces from '~/hoc/withLandingNamespaces'
import { getStaticFilePath } from '../../../utils/helpers'

@withLandingNamespaces()
export default class SecondSection extends React.Component {
  render () {
    const { t } = this.props

    const items = [
      { id: 'item_1', image: '1.svg' },
      { id: 'item_2', image: '2.svg' },
      { id: 'item_3', image: '3.svg' },
      { id: 'item_4', image: '4.svg' }
    ]

    return (
      <div className='gw-second-section'>
        <h1
          className='gw-second-section__title'
          dangerouslySetInnerHTML={{ __html: t('second_section.title') }}
        />
        <div className='gw-second-section-items'>
          {items.map(item =>
            <div className='gw-second-section-items-item' key={item.id}>
              <img
                className='gw-second-section-items-item__image'
                src={getStaticFilePath(`assets/landing-pw/second-section/${item.image}`)}
                draggable={false}
              />
              <h1
                className='gw-second-section-items-item__title'
                dangerouslySetInnerHTML={{ __html: t(`second_section.items.${item.id}.heading`) }}
              />
              <span
                className='gw-second-section-items-item__description'
                dangerouslySetInnerHTML={{ __html: t(`second_section.items.${item.id}.description`) }}
              />
            </div>)}
        </div>
      </div>
    )
  }
}
