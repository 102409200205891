import React from 'react'
import bemCx from 'bem-modifiers'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HeroVoucherContent from '~/components/Festival/common/HeroVoucherContent'
import HeroFilters from '../HeroFilters/HeroFiltersContainer'
import moment from 'moment'

import { FESTIVAL_EDITION_STATES } from '~/utils/consts'
import Swiper from 'react-id-swiper'
import PictureTag from '~/components/PictureTag'

const TIMEOUT_DURATION = 3000

const Content = ({
  isVoucher,
  isPresale,
  festivalName,
  festivalData,
  regions,
  heroWithPrice,
  heroWithDate,
  heroWithTag,
  buttonLabel,
  buttonLabelSecond,
  t
}) => {

  const { startsAt, endsAt } = festivalData || {}

  return (
    isVoucher
      ? <HeroVoucherContent
        festivalName={festivalName}
        festivalData={festivalData}
        regions={regions}
      />
      : <div className='hero-section__text'>
        <div>
          <span
            className='hero-section__event'
          >
            {endsAt && startsAt && t('hero_section.event', {
              startDate: moment.parseZone(startsAt).format('DD.MM'),
              date: moment.parseZone(endsAt).format('DD.MM') })
            }
          </span>
        </div>
        <h1
          className='hero-section__title'
          dangerouslySetInnerHTML={{ __html: t('hero_section.title') }}
        />
        <HeroFilters
          locationModifiers={['geolocation', 'hero', 'hero-gw']}
          peopleModifiers={['people', 'hero-gw']}
          buttonModifiers={['gw', 'sales']}
          buttonLabel={buttonLabel}
          buttonLabelSecond={buttonLabelSecond}
          festivalData={festivalData}
        />
        <span
          className='hero-section__description'
          dangerouslySetInnerHTML={{ __html: t('hero_section.description') }}
        />
      </div>
  )
}

@withLandingNamespaces()
export default class HeroSection extends React.Component {

  componentDidMount () {
    this.initSwiper()
    this.changeAdSlide()
  }

  componentWillUnmount () {
    this.swiper && this.swiper.destroy()
    clearInterval(this.adInterval)
  }

  initSwiper = () => {
    this.swiper && this.swiper.init()
  }

  updateSwiper = () => {
    this.swiper && this.swiper.update()
  }

  changeAdSlide = () => {
    const slides = this.slides
    let i = 0
    const lastSlide = slides ? slides.length - 1 : 0
    this.adInterval = setInterval(() => {
      if (i < lastSlide) {
        this.updateSwiper()
        this.swiper && this.swiper.slideNext()
        i++
      } else {
        clearInterval(this.adInterval)
      }
    }, TIMEOUT_DURATION)
  }

  get priceSentence () {
    const { festivalData, t } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(0)

    return (
      <>
        <span className='hero-section__only-text'>{t('hero_section.only')}</span>
        <span>{price}</span>
        <span>{t('hero_section.per_person')}</span>
      </>
    )
  }

  get slides () {
    return [
      { src: 'pw_1.jpg', href: 'https://wolt.onelink.me/Uy67?pid=collaboration&is_retargeting=true&c=POL_SOCIAL_WOLTWEEK_2X20PLN&af_dp=wolt-app%3A%2F%2FredeemCode%3Fcode%3DWOLTWEEK&af_web_dp=https%3A%2F%2Fwolt.com%2Fpl%2Fme%2Fredeem-code%3Fcode%3DWOLTWEEK%26utm_source%3Dcollaboration%26utm_medium%3Dcpc%26utm_campaign%3DPOL_SOCIAL_WOLTWEEK_2X20PLN%26utm_content%3D' },
      { src: 'pw_2.jpg' },
      { src: 'pw_3.jpg', href: 'https://restpack.pl/restaurant-club/pl/?page=1&utm_source=restaurantclub&utm_medium=banner&utm_id=pizza+week' },
      { src: 'pw_4.jpg' },
      { src: 'pw_5.jpg', href: 'https://wolt.onelink.me/Uy67?pid=collaboration&is_retargeting=true&c=POL_SOCIAL_WOLTWEEK_2X20PLN&af_dp=wolt-app%3A%2F%2FredeemCode%3Fcode%3DWOLTWEEK&af_web_dp=https%3A%2F%2Fwolt.com%2Fpl%2Fme%2Fredeem-code%3Fcode%3DWOLTWEEK%26utm_source%3Dcollaboration%26utm_medium%3Dcpc%26utm_campaign%3DPOL_SOCIAL_WOLTWEEK_2X20PLN%26utm_content%3D' },
    ]
  }

  get buttonLabel () {
    const { festivalData, t } = this.props

    return festivalData === null
      ? t('hero_section.no_festival_button')
      : t('take_part.button')
  }

  get buttonLabelSecond () {
    const { festivalData, t } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(0)

    return festivalData === null
      ? t('hero_section.no_festival_button')
      : t('take_part.button_second', { price })
  }

  render () {
    const {
      festivalData,
      regions,
      festivalEditionState,
      festivalName,
      heroWithDate = true,
      heroWithPrice = true,
      heroWithTag,
      t
    } = this.props
    const isVoucher = festivalEditionState === FESTIVAL_EDITION_STATES.VOUCHER
    const isPresale = festivalEditionState === FESTIVAL_EDITION_STATES.PRESALE

    const slides = this.slides

    const swiperParams = {
      init: false,
      effect: 'fade',
      speed: 500,
      pagination: {
        type: 'bullets',
        el: '.hero-section__pagination',
        bulletClass: 'hero-section__bullet',
        bulletActiveClass: 'hero-section__bullet--active'
      },
      on: {
        slideChange: () => {
          this.updateSwiper()
        },
      }
    }

    return (
      <section className={bemCx('hero-section', [{ 'voucher': isVoucher }, festivalName])}>
        <div className='hero-section__content'>
          <Content
            festivalName={festivalName}
            festivalData={festivalData}
            regions={regions}
            isVoucher={isVoucher}
            isPresale={isPresale}
            heroWithPrice={heroWithPrice}
            heroWithDate={heroWithDate}
            heroWithTag={heroWithTag}
            buttonLabel={this.buttonLabel}
            buttonLabelSecond={this.buttonLabelSecond}
            t={t}
          />
        </div>
        <div className='hero-section__swiper'>
          <Swiper {...swiperParams} ref={node => { if (node) { this.swiper = node.swiper } }}>
            {slides && slides.map((slide, index) => {
              const slideSrc = slide.src || slide
              const slideHref = slide.href || null
              return (<PictureTag
                key={slideSrc}
                src={`assets/landing-pw/slider/${slideSrc}`}
                href={slideHref}
                blankMediaQuery='(max-width: 767px)'
                pictureClassName='swiper-slide'
                className='hero-section__slide-img'
              />)
            })}
          </Swiper>
        </div>
      </section>
    )
  }
}
