import React from 'react'

import Footer from '~/components/Footer/FooterGql'
import HeroSection from '~/components/Festival/pw/HeroSection/HeroSectionGql'
// import HeroBanner from '~/components/HeroBanner'
import TakePartSection from './TakePartSection/TakePartSectionGql'
import SecondSection from './SecondSection'

export default class Index extends React.Component {
  render () {
    const { isSale } = this.props

    return <div>
      <div className='container-red'>
        <HeroSection
          heroWithTag
        />
      </div>
      <div className='container-white' >
        <SecondSection />
      </div>
      {isSale && <TakePartSection modifiers='index' withRespect={false} />}
      <Footer />
    </div>
  }
}
