import DropdownSelect from '~/components/DropdownSelect'
import { LinkButton } from '../Button/LinkButton'
import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'
import { processEnv } from '../../../../utils/helpers'
import queryString from 'query-string'

export default ({
  people,
  peopleOptions,
  loading,
  setLoading,
  setPeopleValue,
  buttonLabel,
  buttonLabelSecond,
  locationModifiers,
  peopleModifiers,
  buttonModifiers,
  festivalData,
  redirectParams,
  isPresale,
  iconRight,
  alternativePrefix,
  mainClass
}) => {

  const route = queryString.stringifyUrl({
    url: processEnv.NEW_CLUB_URL + '/restaurants/festival/pizzaweek',
    query: redirectParams
  })

  return (
    <div className='hero-filters'>
      <div className='hero-filters__dropdowns'>
        <GeolocationDropdownSelect
          instanceId='location-select-hero'
          modifiers={locationModifiers}
          disabled={festivalData === null}
          alternativePrefix={alternativePrefix}
        />
        <DropdownSelect
          modifiers={peopleModifiers}
          valueContainerIcon='user'
          options={peopleOptions}
          defaultValue={people}
          value={people}
          onChange={value => setPeopleValue(value)}
          isSearchable={false}
          hideSelectedOptions={false}
          disabled={festivalData === null}
          alternativePrefix={alternativePrefix}
        />
      </div>
      <LinkButton
        modifiers={['primary', buttonModifiers, 'sales']}
        route={route}
        params={redirectParams}
        label={buttonLabel}
        labelSecond={null}
        onClick={setLoading}
        loading={loading}
        disabled={loading || festivalData === null}
        iconRight={iconRight}
        mainClass={mainClass}
      />
    </div>
  )
}
