import bemCx from 'bem-modifiers'
import Link from '~/components/Link'

export const LinkButton = ({
  disabled,
  loading,
  className,
  modifiers = 'primary',
  label,
  labelSecond,
  route,
  params,
  onClick,
  prefetch,
  disableSpaceBetween,
  iconLeft,
  iconRight,
  mainClass = false,
  ...otherProps
}) => {
  const isLabelArray = Array.isArray(label)
  const isSecondLabelArray = Array.isArray(labelSecond)
  const spaceBetween = !disableSpaceBetween && isLabelArray

  const classNameString = bemCx('button', [modifiers, { 'space-between': spaceBetween }, { disabled }], className)
  const mainClassString = mainClass ? `button--${mainClass}` : 'button'

  return disabled ? (
    <div className={classNameString}>
      {loading ?
        <span className='button__loader' />
        :
        <span className='button__label'>
          {label}
        </span>
      }
    </div>
  ) : (
    <Link
      className={classNameString}
      route={route}
      params={params}
      onClick={onClick}
      disabled={disabled}
      prefetch={prefetch}
      {...otherProps}
    >
      {iconLeft && <i className={`button__icon i-${iconLeft}`} />}
      {isLabelArray ?
        label.map((item, i) => <span key={i} className={`${mainClassString}__label-first`}>{item}</span>)
        :
        <span className={`${mainClassString}__label-first`}>{label}</span>
      }
      {isSecondLabelArray ?
        label.map((item, i) => <span key={i} className={`${mainClassString}__label-second`}>{item}</span>)
        :
        <span className={`${mainClassString}__label-second`}>{labelSecond}</span>
      }
      {iconRight && <i className={`button__icon i-${iconRight}`} />}
    </Link>
  )
}
